import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// import Rating from "./Rating";
// import Pagination from "./pagination";
import { useDispatch, useSelector } from "react-redux";
import { listProduct } from "../../Redux/Actions/ProductActions";
import { listType } from "../../Redux/Actions/TypesActions";
import Loading from "../LoadingError/Loading";
import Message from "../LoadingError/Error";

const ShopSection = (props) => {
  const  keyword  = props;
  const dispatch = useDispatch();

  const productList = useSelector((state) => state.productList);
  const { loading, error, products } = productList;

console.log(products)
  useEffect(() => {
    dispatch(listProduct(keyword));
  }, [dispatch, keyword]);

  const typeList = useSelector((state) => state.typeList);
  const { types } = typeList;

console.log(types)
  useEffect(() => {
    dispatch(listType(keyword));
  }, [dispatch, keyword]);


  console.log(listProduct)
  return (
    <>
      <div className="container">
        <div className="section">
          <div className="row">
            <div className="col-lg-12 col-md-12 article">
              <div className="shopcontainer row">
              
                {loading ? (
                  <div className="mb-5">
                    <Loading />
                  </div>
                ) : error ? (
                  <Message variant="alert-danger">{error}</Message>
                ) : (
                  <>
                    {products.map((product) => (
                      <div
                        className="shop col-lg-4 col-md-6 col-sm-6"
                        key={product._id}
                      >
                        <div className="border-product">
                          <Link to={`/search/${product.category }`}>
                            <div className="shopBack">
                              <img src={product.image} alt={product.name} />
                            </div>
                          </Link>

                          <div className="shoptext">
                            <p>
                              <Link to={`/search/${product.category}`}>
                                {product.name}
                              </Link>
                            </p>
                        
                       </div>
                        </div>
                      </div>
                    ))}
                   
                  </>
                )}
      
                
             </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShopSection;