import axios from 'axios';


// *********** Send email
export const SendEmail = async ({
  orderItems,
  shippingAddress,
  setSend,
}) => {
  try {
    const datas =  {orderItems,shippingAddress,} ;
    let res = await axios.post(`https://orionmarketing.onrender.com/send`, datas);
    if (res) {
      setSend(res.data);
    }
  } catch (error) {
    alert(error.response.data.message);
  }
};
