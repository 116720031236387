import axios from "axios";
import {
 
  TYPE_LIST_FAIL,
  TYPE_LIST_REQUEST,
  TYPE_LIST_SUCCESS,
  TYPE_DETAILS_REQUEST,
  TYPE_DETAILS_SUCCESS ,
  TYPE_DETAILS_FAIL,
  TYPES_CREATE_REVIEW_FAIL,
  TYPES_CREATE_REVIEW_SUCCESS,
  TYPES_CREATE_REVIEW_REQUEST,
  
     
} from "../Constants/TypesConstants";
import { logout } from "./userActions";



// PRODUCT types LIST
export const listType =
  (keyword = " ", pageNumber = " ") =>
  async (dispatch) => {
    try {
      dispatch({ type: TYPE_LIST_REQUEST });
      const { data } = await axios.get(
       ` https://orionmarketing.onrender.com/api/types?keyword=${keyword}&pageNumber=${pageNumber}`
      );
      dispatch({ type: TYPE_LIST_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: TYPE_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };


  // SINGLE PRODUCT TYPE
  export const listTypeDetails = (id) => async (dispatch) => {
    try {
      dispatch({ type: TYPE_DETAILS_REQUEST });
      const { data } = await axios.get(`https://orionmarketing.onrender.com/api/types/${id}`);
      dispatch({ type: TYPE_DETAILS_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: TYPE_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  // PRODUCT REVIEW CREATE
export const createTypeReview =
(typeId, review) => async (dispatch, getState) => {
  try {
    dispatch({ type: TYPES_CREATE_REVIEW_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.post(`https://orionmarketing.onrender.com/api/types/${typeId}/review`, review, config);
    dispatch({ type: TYPES_CREATE_REVIEW_SUCCESS });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: TYPES_CREATE_REVIEW_FAIL,
      payload: message,
    });
  }
};







