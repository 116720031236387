import {
 
    TYPE_LIST_FAIL,
    TYPE_LIST_REQUEST,
    TYPE_LIST_SUCCESS,    
    TYPE_DETAILS_REQUEST,
    TYPE_DETAILS_SUCCESS ,
    TYPE_DETAILS_FAIL,
    TYPES_CREATE_REVIEW_FAIL,
    TYPES_CREATE_REVIEW_REQUEST,
    TYPES_CREATE_REVIEW_RESET,
    TYPES_CREATE_REVIEW_SUCCESS,
      
    
  } from "../Constants/TypesConstants";
  
  // PRODUCT TYPES LIST
  export const typeListReducer = (state = { types: [] }, action) => {
    switch (action.type) {
      case TYPE_LIST_REQUEST:
        return { loading: true, types: [] };
      case TYPE_LIST_SUCCESS:
        return {
          loading: false,
          pages: action.payload.pages,
          page: action.payload.page,
          types: action.payload.types,
        };
      case TYPE_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  

  // SINGLE PRODUCT TYPE

export const typeDetailsReducer = (
  state = { type: { reviews: [] } },
  action
) => {
  switch (action.type) {
    case TYPE_DETAILS_REQUEST:
      return { ...state, loading: true };
    case TYPE_DETAILS_SUCCESS:
      return { loading: false, type: action.payload };
    case TYPE_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// PRODUCT REVIEW CREATE
export const typeCreateReviewReducer = (state = {}, action) => {
  switch (action.type) {
    case TYPES_CREATE_REVIEW_REQUEST:
      return { loading: true };
    case TYPES_CREATE_REVIEW_SUCCESS:
      return { loading: false, success: true };
    case TYPES_CREATE_REVIEW_FAIL:
      return { loading: false, error: action.payload };
    case TYPES_CREATE_REVIEW_RESET:
      return {};
    default:
      return state;
  }
};

 
