import React from "react";
import "./App.css";
import "./responsive.css";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomeScreen from "./screens/HomeScreen";
// import SingleProduct from "./screens/SingleProduct";
import Login from "./screens/Login";
import Register from "./screens/Register";
import TypeCartScreen from "./screens/TypeCartScreen";
import ShippingScreen from "./screens/ShippingScreen";
import ProfileScreen from "./screens/ProfileScreen";
import PaymentScreen from "./screens/PaymentScreen";
import PlaceOrderScreen from "./screens/PlaceOrderScreen";
import OrderScreen from "./screens/OrderScreen";
import NotFound from "./screens/NotFound";
import PrivateRouter from "./PrivateRouter";
import HomeScreen1 from "./screens/HomeScreen1";
import SingleProduct2 from "./screens/SingleProduct2";

const App = () => {
  return (
    <Router>
      <Switch>

      <Route path="/types" component={HomeScreen1} exact />
      <Route path="/search/:keyword" component={HomeScreen1} exact />
      <Route path="/page/:pagenumber" component={HomeScreen1} exact />
      <Route
        path="/search/:keyword/page/:pageNumber"
        component={HomeScreen1}
        exact
      />
        <Route path="/" component={HomeScreen} exact />
        <Route path="/search/:keyword" component={HomeScreen} exact />
        <Route path="/page/:pagenumber" component={HomeScreen} exact />
        <Route
          path="/search/:keyword/page/:pageNumber"
          component={HomeScreen}
          exact
        />
        <Route path="/types/:id" component={ SingleProduct2 } />
        {/*<Route path="/products/:id" component={SingleProduct} />*/}
        <Route path="/login" component={Login} />
       
        <Route path="/register" component={Register} />
        <PrivateRouter path="/profile" component={ProfileScreen} />
        <Route path="/cart/:id?" component={ TypeCartScreen } />
        <PrivateRouter path="/shipping" component={ShippingScreen} />
        <PrivateRouter path="/payment" component={PaymentScreen} />
        <PrivateRouter path="/placeorder" component={PlaceOrderScreen} />
        <PrivateRouter path="/order/:id" component={OrderScreen} />
        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default App;
