import React from "react";

const ContactInfo = () => {
  return (
    <div className="contactInfo container">
      <div className="row">
        <div className="col-12 col-md-4 contact-Box">
          <div className="box-info">
            <div className="info-image">
              <i className="fas fa-phone-alt"></i>
            </div>
            <h5>Call Us </h5>
            <p>+91 98932 29248</p>
            <p>+91 97337 35810</p>
            <p>+0731 4029248</p>
          </div>
        </div>
        <div className="col-12 col-md-4 contact-Box">
          <div className="box-info">
            <div className="info-image">
              <i className="fas fa-map-marker-alt"></i>
            </div>
            <h5>Location</h5>
            <p>4, Joy Builder Colony,</p>
            <p> Patrakar Chowraha</p>
            <p>Near SBI Patrakar Branch, Saket</p>
            <p>Indore (M.P)</p>
          </div>
        </div>
        <div className="col-12 col-md-4 contact-Box">
          <div className="box-info">
            <div className="info-image">
              <i className="fas fa-fax"></i>
            </div>
            <h5>Email</h5>
            <p>orionindore@yahoo.co.in</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
