export const TYPE_LIST_REQUEST = "TYPE_LIST_REQUEST";
export const TYPE_LIST_SUCCESS = "TYPE_LIST_SUCCESS";
export const TYPE_LIST_FAIL = "TYPE_LIST_FAIL";

export const TYPE_DETAILS_REQUEST = "TYPE_DETAILS_REQUEST";
export const TYPE_DETAILS_SUCCESS = "TYPE_DETAILS_SUCCESS";
export const TYPE_DETAILS_FAIL = "TYPE_DETAILS_FAIL";


export const TYPES_CREATE_REVIEW_REQUEST = "TYPES_DETAILS_REQUEST";
export const TYPES_CREATE_REVIEW_SUCCESS = "TYPES_DETAILS_SUCCESS";
export const TYPES_CREATE_REVIEW_FAIL = "TYPES_DETAILS_FAIL";
export const TYPES_CREATE_REVIEW_RESET = "TYPES_CREATE_REVIEW_RESET";
